.AllResult{
    margin-top: 30px;
    margin-bottom: 20px;
}
.AllResult .Table{
    overflow-x: scroll;

}
.AllResult table tbody {
    width: 600px;
}